<template>
  <v-app>
    <!--Header-->
    <j-header-service-pages />

    <!--Content-->
    <v-main>
      <v-container class="j-container j-container--fw ma-0 pa-0 px-15 mx-auto">
        <slot />
      </v-container>
    </v-main>
  </v-app>
</template>

<style scoped lang="scss">
.v-main {
  background-color: $j-bg-main;
}
</style>
