<template>
  <v-app-bar
    :color="scssVariables.jBgMain"
    height="90"
    flat
  >
    <div class="d-flex justify-center ml-lg-16 ma-auto">
      <nuxt-link
        to="/"
        :class="[
          'j-link',
          'j-link--img',
          {
            'j-link--pointer-events-none': isMaintenanceModeOn,
          },
        ]"
      >
        <v-img
          :width="96"
          :lazy-src="useImgSrc(images['logo_jozz'])"
          :src="useImgSrc(images['logo_jozz'])"
          alt="logo"
        />
      </nuxt-link>
    </div>
  </v-app-bar>
</template>

<script setup lang="ts">
const images = useAssetsImages();
const scssVariables = useScssVariables();
const isMaintenanceModeOn = useRuntimeConfig()?.public?.maintenance;
</script>
